// Error components must be Client Components
'use client'

import { EmpathicError } from '@/app/components/empathic-error'

/**
 * error.[js|tsx]: https://nextjs.org/docs/app/building-your-application/routing/error-handling
 * global-error.[js|tsx]: https://nextjs.org/docs/app/building-your-application/routing/error-handling#handling-errors-in-layouts
 *
 * This component is called when:
 *  - on the server, when data fetching methods throw or reject
 *  - on the client, when getInitialProps throws or rejects
 *  - on the client, when a React lifecycle method (render, componentDidMount, etc) throws or rejects
 *      and was caught by the built-in Next.js error boundary
 */
export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  return (
    <EmpathicError
      title="Oops! A little hiccup occurred"
      message="Don't worry, even the best journeys have unexpected turns. We're here to help you get back on track."
      actionText="Let's try that again"
      onAction={reset}
    />
  )
}

import Image from 'next/image'

import { Button } from '@/app/components/ui/button'

interface EmpathicErrorDisplayProps {
  title: string
  message: string
  actionText: string
  onAction: () => void
}

export function EmpathicError({
  title,
  message,
  actionText,
  onAction
}: EmpathicErrorDisplayProps) {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-br from-purple-50 to-indigo-100 px-4 text-center">
      <Image
        src="/img/cora-logo-text.svg"
        alt="Cora Logo"
        width="150"
        height="38"
        className="mb-8"
      />
      <h2 className="mb-4 text-3xl font-bold text-gray-800">{title}</h2>
      <p className="mb-6 max-w-md text-lg text-gray-600">{message}</p>
      <Button
        onClick={onAction}
        className="rounded-full bg-indigo-600 px-6 py-3 text-lg font-semibold text-white transition-colors hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {actionText}
      </Button>
      <p className="mt-6 text-sm text-gray-500">
        Remember, every challenge is an opportunity for growth. Cora is here to support you
        through this and every step of your journey.
      </p>
    </div>
  )
}
